// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,    
  apiUrl:"https://api.rex.ademanda.com/RecepcionExtranjeroBackend/", 
 baseApi: "https://api.rex.ademanda.com/RecepcionExtranjeroBackend",
 baseApiJwt: "10.100.2.193:8080",
 webJwt: "backoffice.servel-dev.adexus.io", // DNS PÚBLICO
 ws: "ws://api.rex.ademanda.com/RecepcionExtranjeroBackend-0.0.1"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
